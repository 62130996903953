export const isDEV = false

export const WHALEX_API = isDEV ? "http://localhost:9127" : "https://whalex-api.ai42.tv" 
export const PUMP_API = isDEV ? "http://localhost:9124" : "https://pump-api.ai42.tv" 
export const PUMP_CHARTS_API = isDEV ? "http://localhost:9100" : "https://aggregator-api.ai42.tv"
export const WHALEX_CHARTS_API = isDEV ? "http://localhost:9101" : "https://whalex-aggregator-api.ai42.tv"
export const SOCIAL_API = isDEV ? "http://localhost:9125" : "https://pump-social-api.ai42.tv"


export const nets = {
    tbnb:{
      whaleX:"0xAe317A5Da16A825d897df41B37c24C8444142F09",
      factory:"0xCCBCC24154Ce4C07C66462a2948BB0419E02cbBf",
      v2Router:"0xcCb6973225FE7e61B97E0fe1a7822EEc56884d92",
      dai:"0xd46D91319364F57775E23E39d046A398BED26F92",
      weth:"0x1c4ed1f3d87e5a6100cffd37b2a4e739f5b6bdd9",
      daiInitial:"3600000000000000000000",
      netID:97,
      symbol: "BNB Testnet",
      rpc: "https://bsc-testnet-dataseed.bnbchain.org"
    },
    base:{
      whaleX:null,
      factory:"0x3344573A8b164D9ed32a11a5A9C6326dDB3dC298",
      v2Router:"0x327Df1E6de05895d2ab08513aaDD9313Fe505d86",
      dai:"0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb",
      weth:"0x4200000000000000000000000000000000000006",
      daiInitial:"3600000000000000000000",
      netID:8453,
      symbol: "BASE",
      rpc: "https://mainnet.base.org"
    },
    bnb:{
      whaleX:null,
      factory:"0x61AB60249cf8CAb5c5aa59CE3906c0Cd5db28703",
      v2Router:"0x10ED43C718714eb63d5aA57B78B54704E256024E",
      dai:"0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
      weth:"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      daiInitial:"3600000000000000000000",
      netID:56,
      symbol: "BNB",
      rpc: "https://bsc.drpc.org"
    }
}
